import(/* webpackMode: "eager" */ "/home/buzlin/public_html/app/(store)/(booking)/(with-footer)/home-3/components/canvas/canvas.tsx");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/app/(store)/(booking)/(with-footer)/home-3/components/deals/deals.tsx");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/app/(store)/(booking)/(with-footer)/home-3/components/masters/masters.tsx");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/app/(store)/(booking)/(with-footer)/home-3/components/near-you/near-you.tsx");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/app/(store)/(booking)/(with-footer)/home-3/components/recomended/recomended.tsx");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/app/(store)/(booking)/(with-footer)/home-3/components/search-field/search-field.tsx");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/app/(store)/(booking)/components/services/services.tsx");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/app/(store)/(booking)/components/stories/stories.tsx");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/components/back-button/back-button.tsx");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/components/country-indicator/country-indicator.tsx");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/components/header-buttons/header-buttons.tsx");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/components/header/links.tsx");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/components/header/mobile-sidebar.tsx");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/components/slidable-product-list/slidable-product-list.tsx");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/components/translate/translate.tsx");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/buzlin/public_html/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js")